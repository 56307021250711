import ReactDOM from "react-dom";
import "./css/profile/profile.css";
import "./css/timetable/timetable.css";
import "./css/profile/profile_popup.css";
import "./css/backdrop.css";
import "./css/timetable/tt_timetable_popups.css";
import "./css/timetable/tt_download_recording_popup.css";
// import './css/timetable/callendar.css'
import "./css/loader.css";
import "./css/dashboard/dashboard.css";
import "./css/header/profile_module.css";
import "./css/header/header_module.css";
import "./css/login/login.css";
import "./css/timetable/calendar_timetable2.css";
// import './header_module.css'
import "./css/header/header_module.css";
import "./global.css"

import App from "./App";

import { Provider, useSelector } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import { rootstore, persistor } from "./redux/store";
import { PersistGate } from "redux-persist/integration/react";
import { useEffect } from "react";


function Root() {
  const currentMode = useSelector(state => state.auth.currentMode); 

  useEffect(() => {
    document.body.classList.remove('light-mode', 'dark-mode', 'blue-mode', 'contrast-mode');
    document.body.classList.add(`${currentMode}-mode`);
  }, [currentMode]); 

return(
  
  <Router>
    <App/>
  </Router>
);
}
ReactDOM.render(<Provider store={rootstore}>
  <PersistGate loading={null} persistor={persistor}>
    <Root/>
  </PersistGate>
</Provider>, document.getElementById('root'))
