import React from 'react'
import Header from '../../components/header';
import Nav from './nav'
import { connect } from 'react-redux'
import './indexReport.css';

class Exam extends React.Component  {
   constructor(props)
   {super(props);}
 
render(){
   return(
      <>
      <div className="exam-report-main">
         <Header/>
         <Nav/>
         </div>
      </>
   ) } }
const mapStateToProps = state =>({auth:state.auth})
export default connect(mapStateToProps)(Exam)