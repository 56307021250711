import Header from "./components/header/index";
import Login from "../src/modules/login/login";
import Routes from "./routes";
import Loader from "./components/loader";
import moment from "moment";
import { connect } from "react-redux";
import React, { useEffect, useState } from "react";
import config from "./config";
import { useDispatch } from 'react-redux';
import { setMode } from "./redux/actions";
import "./global.css"
import { useSelector } from "react-redux";
import ModeToggle from "./ModeToggle";
import header from "./components/header/index";


function App(props) { 
  const dispatch = useDispatch();
  const mode = useSelector((state) => state.auth.mode);

  // useEffect(() => {
   
  //   const storedMode = localStorage.getItem('mode') || 'light';
  //   dispatch(setMode(storedMode)); 
  // }, [dispatch]);


  // useEffect(() => {
  //   document.body.className = mode; // Update theme class globally
  // }, [mode]);
  // useEffect(() => {
  // if(config.env ==="prod"){
  //   document.addEventListener('contextmenu', event => event.preventDefault());
  //   document.onkeydown = function (e) {
  //     if(e.keyCode == 123) {
  //       return false;
  //     }
  //     if(e.ctrlKey && e.shiftKey && e.keyCode == 73){
  //       return false;
  //     }
  //     if(e.ctrlKey && e.shiftKey && e.keyCode == 74) {
  //       return false;
  //     }
  //     if(e.ctrlKey && e.keyCode == 85) {
  //       return false;
  //     }
  //   }
  // }
  //  }, [])
  return (
    <div>
    
      <Routes />

    </div>
  );
}

// export default App

export default App;
