export function action(payload) {
    return { type: payload.type, payload };
  }
  
  export const toggleDarkMode = () => ({
    type: 'TOGGLE_DARK_MODE_REQUEST',
  });
  
  export const setMode = (mode) => ({
    type: 'SET_MODE',
    payload: mode,
  });