import { put, takeEvery, select } from 'redux-saga/effects';
import { setMode } from '../actions';

function* handleModeChange({ payload: newMode }) {
  // Update the Redux store
  yield put(setMode(newMode));

  // Save the theme to localStorage
  localStorage.setItem('mode', newMode);

  // Apply the theme class to the <body> element
  document.body.className = ''; // Remove all classes
  document.body.classList.add(newMode); // Add the new theme class

  console.log("Mode changed to:", newMode);
}

// Watch for the SET_THEME_REQUEST action
function* watchModeChange() {
  yield takeEvery('SET_MODE_REQUEST', handleModeChange);
}

export default watchModeChange;