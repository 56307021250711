import { createStore, applyMiddleware } from "redux";
import rootReducer from "../reducers";
import hardSet from 'redux-persist/lib/stateReconciler/hardSet';
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import createSagaMiddleware from 'redux-saga'

import rootSaga from '../saga/'

const sagaMiddleware = createSagaMiddleware()

const persistConfig = {
    key: 'root',
    storage,
    stateReconciler: hardSet,
}


const persistedReducer = persistReducer(persistConfig, rootReducer)
export const rootstore = createStore(persistedReducer, applyMiddleware(sagaMiddleware))
sagaMiddleware.run(rootSaga)
export const persistor = persistStore(rootstore)



// import { createStore, applyMiddleware, compose } from "redux";
// import rootReducer from "../reducers";
// import hardSet from "redux-persist/lib/stateReconciler/hardSet";
// import { persistStore, persistReducer } from "redux-persist";
// import storage from "redux-persist/lib/storage";

// import createSagaMiddleware from "redux-saga";
// import rootSaga from "../saga/";

// const sagaMiddleware = createSagaMiddleware();

// // Redux Persist Configuration
// const persistConfig = {
//   key: "root", // Key for the persisted state
//   storage,     // LocalStorage for web
//   stateReconciler: hardSet, // Overwrites incoming state
//   whitelist: ["auth"], // Persist only specific reducers (e.g., 'auth')
// };

// const persistedReducer = persistReducer(persistConfig, rootReducer);

// // Enable Redux DevTools Extension
// const composeEnhancers =
//   window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

// // Create Redux Store with Saga Middleware
// export const rootstore = createStore(
//   persistedReducer,
//   composeEnhancers(applyMiddleware(sagaMiddleware))
// );

// // Clear persisted data during development

// // Run Root Saga
// sagaMiddleware.run(rootSaga);

// // Create Persistor
// export const persistor = persistStore(rootstore);
